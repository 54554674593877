<template>
  <div>
    <a-button-group>
      <a-popconfirm title="确定通过吗" :disabled="item.audit_status !== 'pending'" @confirm="approve">
        <a-button type="primary" size="small" :loading="approveLoading" :disabled="item.audit_status !== 'pending'">
          通过
        </a-button>
      </a-popconfirm>
      <a-popconfirm title="确定拒绝吗" :disabled="item.audit_status !== 'pending'" @confirm="reject">
        <a-button type="danger" size="small" :loading="rejectLoading" :disabled="item.audit_status !== 'pending'">
          拒绝
        </a-button>
      </a-popconfirm>
    </a-button-group>
  </div>
</template>

<script>
import { sortingReportPackageApprove, sortingReportPackageReject } from "@/apis/sorting";

export default {
  props: ["item"],
  data() {
    return {
      approveLoading: false,
      rejectLoading: false,
    };
  },
  methods: {
    approve() {
      this.approveLoading = true;
      sortingReportPackageApprove({ id: this.item.id })
        .then((data) => {
          this.$message.success("通过成功");
          this.$emit("update", data);
        })
        .finally(() => {
          this.approveLoading = false;
        });
    },
    reject() {
      this.rejectLoading = true;
      sortingReportPackageReject({ id: this.item.id })
        .then((data) => {
          this.$message.success("拒绝成功");
          this.$emit("update", data);
        })
        .finally(() => {
          this.rejectLoading = false;
        });
    },
  },
};
</script>

<style scoped></style>
